<template>
    <div class="FAPO-page">
        <div class="FAPO-content" v-if="Object.keys(EHDDetails).length > 0">
            <div class="FAPO-main">
                <div class="FAPO-order-preview">
                    <p>
                        <router-link
                            :to="{
                                name: 'EHDPage',
                                params: { id: EHDDetails.id },
                            }"
                        >
                            <span class="back-button"> Back </span>
                            <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                width="13px"
                                height="10px"
                                viewBox="0 0 284.935 284.936"
                                style="enable-background:new 0 0 284.935 284.936; fill:#888;"
                                xml:space="preserve"
                            >
                                <g>
                                    <path
                                        d="M222.701,135.9L89.652,2.857C87.748,0.955,85.557,0,83.084,0c-2.474,0-4.664,0.955-6.567,2.857L62.244,17.133
		c-1.906,1.903-2.855,4.089-2.855,6.567c0,2.478,0.949,4.664,2.855,6.567l112.204,112.204L62.244,254.677
		c-1.906,1.903-2.855,4.093-2.855,6.564c0,2.477,0.949,4.667,2.855,6.57l14.274,14.271c1.903,1.905,4.093,2.854,6.567,2.854
		c2.473,0,4.663-0.951,6.567-2.854l133.042-133.044c1.902-1.902,2.854-4.093,2.854-6.567S224.603,137.807,222.701,135.9z"
                                    />
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </svg>
                        </router-link>
                        Order Preview
                    </p>
                    <button class="download-button" @click="openPDFView">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.325"
                            height="13.665"
                            viewBox="0 0 14.325 13.665"
                        >
                            <path
                                data-name="Path 2443"
                                d="M72.354 9.985a.471.471 0 0 1 .471.471v1.7a2.733 2.733 0 0 1-2.733 2.733h-8.859a2.733 2.733 0 0 1-2.733-2.737v-1.7a.471.471 0 0 1 .942 0v1.7a1.791 1.791 0 0 0 1.791 1.791h8.859a1.791 1.791 0 0 0 1.791-1.791v-1.7a.471.471 0 0 1 .471-.471zM65.662 1.22a.471.471 0 0 1 .47.44v7.806l2.035-1.9a.471.471 0 0 1 .642 0l.024.024a.471.471 0 0 1 0 .642l-.024.024-2.824 2.636-.026.023-.007.006-.017.013-.012.008-.013.008-.012.007-.019.01h-.008l-.017.008-.012.005-.016.006h-.014L65.8 11h-.017l-.047.01h-.074a.474.474 0 0 1-.072-.005h-.022a.471.471 0 0 1-.416-.117l-2.827-2.632a.471.471 0 1 1 .643-.689l2.223 2.075V1.691a.471.471 0 0 1 .471-.471z"
                                style="fill:#333"
                                transform="translate(-58.5 -1.22)"
                            />
                        </svg>
                        Download PDF
                    </button>
                </div>
                <div class="FAPO-header">
                    <div class="FAPO-collage">
                        <img
                            :src="
                                EHDDetails.collage_image
                                    ? EHDDetails.collage_image
                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                            "
                            class="FAPO-collage-img"
                        />
                    </div>
                    <div class="FAPO-address">
                        <h1>House Details</h1>
                        <p>{{ EHDDetails.unit_address }}</p>
                    </div>
                    <div class="FAPO-total-cost">
                        <p>Total Cost</p>
                        <p :class="requiredDomain == false ? 'blur' : ''">
                            ₹{{
                                convertToIndianNumberSystem(
                                    EHDDetails.total_cost,
                                )
                            }}
                        </p>
                    </div>
                </div>
                <div class="FAPO-products">
                    <template v-for="scene in EHDDetails.scene_data">
                        <Accordion
                            v-if="
                                scene.base_products.length > 0 ||
                                    scene.base_services.length > 0 ||
                                    scene.one_time_custom_product.length > 0
                            "
                            :key="scene.id"
                            :rightCenter="true"
                            :activeHeader="true"
                            :id="scene.id"
                            @selectedAccordian="selectedAccordian"
                            style="margin-bottom: 20px;"
                            :selected="selectedAccd"
                            class="accordion-finalise"
                        >
                            <template #header-left>
                                <div class="scene-details">
                                    <img
                                        :src="
                                            scene.scene_image_data != null &&
                                            scene.scene_image_data.length != 0
                                                ? scene.scene_image_data[0]
                                                      .scene_image_url
                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                        "
                                        class="room-img"
                                    />
                                    <div class="room-theme">
                                        <p class="room">
                                            {{ scene.room_type }}
                                        </p>
                                        <p class="item-count">
                                            <span
                                                class="count-services"
                                                v-if="
                                                    scene.active_products_count >
                                                        0
                                                "
                                            >
                                                {{
                                                    scene.active_products_count
                                                }}
                                                Products
                                            </span>
                                            <span
                                                v-if="scene.active_services > 0"
                                                class="count-services"
                                            >
                                                {{ scene.active_services }}
                                                Services</span
                                            >
                                            <span
                                                v-if="
                                                    scene
                                                        .one_time_custom_product
                                                        .length > 0
                                                "
                                                class="count-services"
                                                >{{
                                                    scene
                                                        .one_time_custom_product
                                                        .length
                                                }}
                                                Custom Products</span
                                            >
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template #header-right-center>
                                <div class="scene-info">
                                    <div
                                        class="scene-price"
                                        :class="
                                            requiredDomain == false
                                                ? 'blur'
                                                : ''
                                        "
                                    >
                                        ₹
                                        {{
                                            convertToIndianNumberSystem(
                                                scene.price,
                                            )
                                        }}
                                    </div>
                                </div>
                            </template>
                            <template #body>
                                <div class="products-list">
                                    <template
                                        v-if="scene.base_products.length > 0"
                                    >
                                        <div
                                            v-for="product in scene.base_products.filter(
                                                value =>
                                                    value.available == true,
                                            )"
                                            :key="product.id"
                                        >
                                            <div class="product">
                                                <div class="product-info">
                                                    <div class="product-img">
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'ProductDetailsPage',
                                                                params: {
                                                                    id:
                                                                        product.id,
                                                                },
                                                            }"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </router-link>
                                                    </div>
                                                    <div class="product-detail">
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'ProductDetailsPage',
                                                                params: {
                                                                    id:
                                                                        product.id,
                                                                },
                                                            }"
                                                        >
                                                            <p
                                                                class="product-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        75,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            75
                                                                    "
                                                                    >....</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="product-name-mobile"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        25,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            25
                                                                    "
                                                                    >....</span
                                                                >
                                                            </p>
                                                            <template
                                                                v-if="
                                                                    product.vendor !=
                                                                        null
                                                                "
                                                            >
                                                                <p
                                                                    class="product-brand"
                                                                    v-if="
                                                                        product.vendor.toLowerCase() !=
                                                                            'dummy'
                                                                    "
                                                                >
                                                                    {{
                                                                        product.vendor
                                                                    }}
                                                                </p>
                                                            </template>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="product-price">
                                                    <div class="action">
                                                        <div
                                                            class="number-of-products"
                                                        >
                                                            <div
                                                                class="remove-one"
                                                                v-if="
                                                                    product.active_quantity !=
                                                                        0
                                                                "
                                                                @click="
                                                                    changeCount(
                                                                        {
                                                                            product: product,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: false,
                                                                            ehd_id:
                                                                                EHDDetails.id,
                                                                        },
                                                                    )
                                                                "
                                                            >
                                                                <svg
                                                                    viewbox="0 0 10 10"
                                                                >
                                                                    <line
                                                                        x1="6"
                                                                        y1="11.5"
                                                                        x2="16"
                                                                        y2="11.5"
                                                                        fill="none"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        vector-effect="non-scaling-stroke"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="disable-icon"
                                                            >
                                                                <svg
                                                                    viewbox="0 0 10 10"
                                                                >
                                                                    <line
                                                                        x1="6"
                                                                        y1="11.5"
                                                                        x2="16"
                                                                        y2="11.5"
                                                                        fill="none"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        vector-effect="non-scaling-stroke"
                                                                    />
                                                                </svg>
                                                            </div>

                                                            <div class="total">
                                                                {{
                                                                    product.active_quantity
                                                                }}
                                                            </div>

                                                            <div
                                                                class="add-one"
                                                                @click="
                                                                    changeCount(
                                                                        {
                                                                            product: product,
                                                                            scene_id:
                                                                                scene.id,
                                                                            enable: true,
                                                                            ehd_id:
                                                                                EHDDetails.id,
                                                                        },
                                                                    )
                                                                "
                                                            >
                                                                <svg
                                                                    viewbox="0 0 10 10"
                                                                >
                                                                    <line
                                                                        x1="6"
                                                                        y1="11.5"
                                                                        x2="15"
                                                                        y2="11.5"
                                                                        fill="none"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        vector-effect="non-scaling-stroke"
                                                                    />
                                                                    <line
                                                                        y1="7"
                                                                        x1="10.5"
                                                                        y2="16"
                                                                        x2="10.5"
                                                                        fill="none"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        vector-effect="non-scaling-stroke"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="price-breakup">
                                                        <p
                                                            class="current-price"
                                                            v-if="
                                                                product.item_price !=
                                                                    0
                                                            "
                                                            :class="
                                                                requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''
                                                            "
                                                        >
                                                            ₹
                                                            {{
                                                                convertToIndianNumberSystem(
                                                                    product.item_price,
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-if="scene.base_services.length > 0"
                                    >
                                        <div class="service-container">
                                            <div
                                                class="service"
                                                v-for="services in scene.base_services"
                                                :key="services.type"
                                            >
                                                <div
                                                    v-for="service in services.data"
                                                    :key="service.id"
                                                    :class="{
                                                        'service-disabled':
                                                            service.active ==
                                                            false,
                                                    }"
                                                >
                                                    <div
                                                        class="service-wrapper"
                                                    >
                                                        <div
                                                            class="service-img"
                                                        >
                                                            <div
                                                                class="img-wrapper"
                                                            >
                                                                <img
                                                                    v-if="
                                                                        service.active ==
                                                                            true
                                                                    "
                                                                    :src="
                                                                        service.service_icon_url !==
                                                                        null
                                                                            ? service.service_icon_url
                                                                            : require('@/assets/images/enable_service.png')
                                                                    "
                                                                />
                                                                <img
                                                                    v-else
                                                                    class="inActive"
                                                                    :src="
                                                                        service.service_icon_url !==
                                                                        null
                                                                            ? service.service_icon_url
                                                                            : require('@/assets/images/disable_service.png')
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="service-detail"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    service.name
                                                                }}
                                                                <span
                                                                    class="service-description-tag"
                                                                    @click="
                                                                        showServiceDescription(
                                                                            service.description,
                                                                        )
                                                                    "
                                                                    >View
                                                                    Details</span
                                                                >
                                                            </p>
                                                            <template
                                                                v-if="
                                                                    service.vendor !=
                                                                        null
                                                                "
                                                            >
                                                                <p
                                                                    class="service-brand"
                                                                    v-if="
                                                                        service.vendor.toLowerCase() !=
                                                                            'dummy'
                                                                    "
                                                                >
                                                                    Vendor:
                                                                    {{
                                                                        service.vendor
                                                                    }}
                                                                </p>
                                                            </template>
                                                        </div>
                                                        <div
                                                            class="service-price"
                                                        >
                                                            <div class="action">
                                                                <span
                                                                    v-if="
                                                                        service.active ==
                                                                            true
                                                                    "
                                                                    class="remove"
                                                                    @click="
                                                                        toggleServices(
                                                                            {
                                                                                service_id:
                                                                                    service.id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    Remove
                                                                </span>
                                                            </div>
                                                            <div
                                                                class="price-breakup"
                                                            >
                                                                <p
                                                                    class="current-price"
                                                                    v-if="
                                                                        service.price !=
                                                                            0
                                                                    "
                                                                    :class="
                                                                        requiredDomain ==
                                                                        false
                                                                            ? 'blur'
                                                                            : ''
                                                                    "
                                                                >
                                                                    ₹
                                                                    {{
                                                                        convertToIndianNumberSystem(
                                                                            service.price,
                                                                        )
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-if="
                                            scene.one_time_custom_product
                                                .length > 0
                                        "
                                    >
                                        <div
                                            v-for="product in scene.one_time_custom_product"
                                            :key="product.id"
                                        >
                                            <div class="product">
                                                <div class="product-info">
                                                    <div class="product-img">
                                                        <img
                                                            :src="
                                                                product.img_url
                                                                    ? product.img_url
                                                                    : require('@/assets/images/default-product-img.png')
                                                            "
                                                        />
                                                    </div>
                                                    <div class="product-detail">
                                                        <router-link
                                                            :to="{
                                                                name:
                                                                    'ProductDetailsPage',
                                                                params: {
                                                                    id:
                                                                        product.prod_id,
                                                                },
                                                            }"
                                                        >
                                                            <p
                                                                class="product-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        75,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            75
                                                                    "
                                                                    >....</span
                                                                >
                                                            </p>
                                                            <template
                                                                v-if="
                                                                    product.vendor !=
                                                                        null
                                                                "
                                                            >
                                                                <p
                                                                    class="product-brand"
                                                                    v-if="
                                                                        product.vendor.toLowerCase() !=
                                                                            'dummy'
                                                                    "
                                                                >
                                                                    {{
                                                                        product.vendor
                                                                    }}
                                                                </p>
                                                            </template>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                <div class="product-price">
                                                    <div
                                                        class="remove"
                                                        v-if="
                                                            product.active ==
                                                                true
                                                        "
                                                        @click="
                                                            toggleCustomProducts(
                                                                {
                                                                    product: product,
                                                                    scene_id:
                                                                        scene.id,
                                                                    enable: false,
                                                                    ehd_id:
                                                                        EHDDetails.id,
                                                                },
                                                            )
                                                        "
                                                    >
                                                        Remove
                                                    </div>
                                                    <div class="price-breakup">
                                                        <p
                                                            class="current-price"
                                                            v-if="
                                                                product.price !=
                                                                    0
                                                            "
                                                            :class="
                                                                requiredDomain ==
                                                                false
                                                                    ? 'blur'
                                                                    : ''
                                                            "
                                                        >
                                                            ₹
                                                            {{
                                                                convertToIndianNumberSystem(
                                                                    product.price,
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </Accordion>
                    </template>
                </div>
            </div>
            <div class="FAPO-sidebar">
                <div class="FAPO-finalise" v-if="!placeOrder">
                    <img
                        :src="
                            EHDDetails.designer_image
                                ? EHDDetails.designer_image
                                : require('assets/images/default-designer.png')
                        "
                        class="designer-img"
                    />

                    <p class="designer-name">{{ EHDDetails.designer }}</p>

                    <p class="finalize-desc">
                        Our designer will review your order details and get in
                        touch within 24 hours to start project execution
                    </p>

                    <Button
                        name="Contact Us"
                        primary
                        class="finalise-button"
                        @click.native="openGetTouchModal"
                    />
                </div>
            </div>
        </div>
        <Modal
            v-if="showDescription"
            @close="showDescription = false"
            transparent
        >
            <div class="service-description">
                <p>Description</p>
                {{ serviceDescription }}
            </div>
        </Modal>
        <Modal v-if="showModal" @close="showModal = false" transparent>
            <div class="modal">
                <div class="modal-view">
                    <p>{{ msg }}</p>
                </div>
            </div>
        </Modal>
        <Modal v-if="getTouchModal" hideClose transparent>
            <div class="finalise">
                <div class="finalise-view thankyou-modal">
                    <img
                        class="thankyou-img"
                        src="@/assets/images/thankyou.png"
                    />
                    <h4>Thank You</h4>
                    <p>Our design team will get in touch within 24 hours</p>
                    <router-link
                        :to="{
                            name: 'MyDesignIdeas',
                        }"
                    >
                        <Button
                            class="explore-designs"
                            name="Explore designs"
                            primary
                        />
                    </router-link>
                </div>
            </div>
        </Modal>
        <Modal v-if="tryAgainModal" transparent @close="closeTryAgainModal">
            <div class="error-modal">
                <div class="error-image">
                    <img src="@/assets/images/balloons.png" />
                </div>
                <p class="header">
                    Something went wrong
                </p>
                <p class="subhead">Please try again</p>
                <div
                    class="result-button"
                    style="display: flex; justify-content: center;"
                >
                    <Button
                        class="delete-draft"
                        name="Try again"
                        primary
                        @click.native="tryApiAgain"
                    />
                </div>
            </div>
        </Modal>
        <template v-if="loaderShow && Object.keys(EHDDetails).length == 0">
            <div class="wait">
                <Loader :loaderType="'snake_loader'" />
            </div>
        </template>
        <Footer />
    </div>
</template>

<script>
import { getDraft } from 'api/userApi'
import { createOrder } from 'api/orderApi'
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import EHDPageModule from 'store/modules/EHDPage'
import FAPOPageModule from 'store/modules/FAPOPage'
import Button from 'componentsv2/Button'
import Accordion from 'componentsv2/Accordion'
import Modal from 'componentsv2/Modal'
import Loader from 'componentsv2/Loader'
import Footer from 'componentsv2/Footer'
import loaderHandler from 'mixins/loader'
import { customise_scene } from 'api/productsApi'

export default {
    name: 'FinaliseAndPlaceOrderPage',
    components: {
        Button,
        Accordion,
        Modal,
        Loader,
        Footer,
    },
    props: {
        requiredDomain: {
            type: Boolean,
        },
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    data() {
        return {
            placeOrder: false,
            order_id: null,
            showModal: false,
            msg: null,
            draftModal: false,
            draftModalMsg: '',
            draftExist: null,
            getTouchModal: false,
            showDescription: false,
            serviceDescription: '',
            countAltered: false,
            scrollPosition: 0,
            selectedAccd: null,
            tryAgainModal: false,
            tryAgainObject: {},
        }
    },
    computed: {
        ...mapState({
            EHDDetails: state => state.EHDPage.EHDDetails,
            priceDetails: state => state.FAPOPage.priceDetails,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Order preview',
        bodyAttrs: {
            class: ['FAPO-page-body'],
        },
    },
    created() {
        this.registerStoreModule('EHDPage', EHDPageModule)
        this.registerStoreModule('FAPOPage', FAPOPageModule)
        this.fetchDraft()
    },
    methods: {
        ...mapActions({
            fetchEHDDetailsv3: 'fetchEHDDetailsv3',
            fetchPriceDetails: 'fetchPriceDetails',
        }),
        closeTryAgainModal() {
            this.tryAgainModal = false
        },
        selectedAccordian(id) {
            this.selectedAccd = id
        },
        finalise() {
            this.placeOrder = true
        },
        openPDFView() {
            this.$router.push({
                name: 'DownloadBOQ',
                query: { ehd_id: this.$route.query.ehd_id },
            })
        },
        showServiceDescription(description) {
            this.showDescription = true
            this.serviceDescription = description
        },
        tryApiAgain() {
            this.tryAgainModal = false
            this.loaderShow = true
            customise_scene(this.tryAgainObject)
                .then(response => {
                    if (response.responseCode == 200) {
                        this.fetchEHDDetailsv3(response.payload.ehd_id)
                        this.loaderShow = false
                    } else {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }, 1000)
                })
        },
        changeCount(obj) {
            this.countAltered = false
            this.loaderShow = true
            let object
            this.scrollPosition = window.scrollY
            document.querySelector('body').style.overflow = 'hidden'
            if (this.countAltered == false) {
                this.countAltered = true
                if (obj.enable == false) {
                    object = {
                        action: 'disable_product',
                        details_id: obj.product.specific_data.filter(
                            value => value.is_part_of_scene == true,
                        )[0].id,
                        scene_id: obj.scene_id,
                    }
                } else {
                    object = {
                        action: 'add_to_scene',
                        product_id: obj.product.id,
                        scene_id: obj.scene_id,
                        quantity: 1,
                    }
                }
                this.tryAgainObject = object
                customise_scene(object)
                    .then(response => {
                        if (response.responseCode == 200) {
                            this.fetchEHDDetailsv3(response.payload.ehd_id)
                                .then(payload => {
                                    if (payload.responseCode == 200) {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                            document.querySelector(
                                                'body',
                                            ).style.overflow = 'auto'
                                        }, 1000)
                                        this.changeScrollPosition()
                                    }
                                })
                                .catch(err => {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                    }, 1000)
                                })
                        } else {
                            this.loaderShow = false
                            this.tryAgainModal = true
                            this.countAltered = false
                        }
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.loaderShow = false
                            this.tryAgainModal = true
                        }, 1000)
                    })
            }
        },
        changeScrollPosition() {
            window.scrollTo(0, this.scrollPosition)
        },
        toggleCustomProducts(obj) {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            this.scrollPosition = window.scrollY
            customise_scene({
                action: 'toggle_custom_product',
                product_id: obj.product.prod_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode == 200) {
                        this.fetchEHDDetailsv3(response.payload.ehd_id)
                            .then(payload => {
                                if (payload.responseCode == 200) {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                        document.querySelector(
                                            'body',
                                        ).style.overflow = 'auto'
                                    }, 1000)
                                    this.changeScrollPosition()
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 1000)
                            })
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
        },
        toggleServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            this.loaderShow = true
            this.scrollPosition = window.scrollY
            customise_scene({
                action: 'toggle_service',
                service_id: obj.service_id,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode == 200) {
                        this.fetchEHDDetailsv3(response.payload.ehd_id)
                            .then(payload => {
                                if (payload.responseCode == 200) {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                        document.querySelector(
                                            'body',
                                        ).style.overflow = 'auto'
                                    }, 1000)
                                    this.changeScrollPosition()
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 1000)
                            })
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
        },
        fetchDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'hidden'
            getDraft(this.$route.query.ehd_id, this.$route.query.scene_id)
                .then(data => {
                    if (data.responseCode == 200) {
                        this.$router.push({
                            name: 'FinaliseAndPlaceOrderPage',
                            query: {
                                ehd_id: data.data.ehd_id,
                                scene_id: this.$route.query.scene_id,
                            },
                        })
                        this.fetchEHDDetailsv3(data.data.ehd_id)
                            .then(payload => {
                                if (payload.responseCode == 200) {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                        document.querySelector(
                                            'body',
                                        ).style.overflow = 'auto'
                                    }, 1000)
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 1000)
                            })
                        this.draftExist = true
                    } else {
                        this.draftExist = false
                        this.fetchEHDDetailsv3(this.$route.query.ehd_id)
                            .then(payload => {
                                if (payload.responseCode == 200) {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                        document.querySelector(
                                            'body',
                                        ).style.overflow = 'auto'
                                    }, 1000)
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 1000)
                            })
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 1000)
                })
        },

        openGetTouchModal() {
            createOrder(this.$route.query.ehd_id).then(data => {
                if (data.responseCode == 2006) {
                    this.showModal = true
                    this.msg = data.responseMessage
                } else {
                    this.fetchPriceDetails(data.order_id)
                    this.order_id = data.order_id
                }
            })
            this.getTouchModal = true
            document.querySelector('body').style.overflow = 'auto'
        },
    },
}
</script>

<style lang="scss">
@import './FinaliseAndPlaceOrderPage.scss';
</style>
